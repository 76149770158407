<template>
	<div>
		<web-header></web-header>
		<div class="retrieve">
			<h3>手机找回密码</h3>
			<div class="loginTop">
				<div v-for="(it,i) in loginList" :key="i" :class="it.id === index ? 'loginActive' : ''">
					<span>{{it.id}}</span>{{it.name}}
				</div>
			</div>

			<div class="phone" v-if="index === 1">
				<div class="phoneInput">
					<el-input placeholder="请输入手机号" v-model="phone"></el-input>
					<img src="../../assets/login/phone.png" alt="">
				</div>
				<div class="yzm">
					<el-input placeholder="请输入短信验证码" v-model="code"></el-input>
					<div @click="getCode">
						<el-button :disabled="btnFlag">{{ result > 0 && result ? time2: "获取验证码" }}</el-button>
					</div>
					<img src="../../assets/login/yzm.png" alt="">
				</div>
				<div class="loginCls">
					<el-button @click="listEvt">下一步</el-button>
				</div>
			</div>

			<div class="phone" v-if="index === 2">
				<div class="phoneInput">
					<el-input v-model="oldPassword" placeholder="请输入新密码" type="password"></el-input>
				</div>
				<div class="yzm">
					<el-input v-model="newPassword" style="width:100%" placeholder="请确认密码" type="password">
					</el-input>
				</div>
				<div class="loginCls">
					<el-button @click="sureUpdatePsd">确认</el-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import webHeader from '../../components/webHeader.vue'
	import compile from '@/utils/secret.js'
	import md5 from 'js-md5';
	import test from '../../utils/test.js';
	export default {
		components: {
			webHeader
		},
		data() {
			return {
				index: 1,
				loginList: [{
						name: '验证手机号',
						id: 1
					},
					{
						name: '设定新密码',
						id: 2
					},
				],
				btnFlag: false,
				timer: null,
				result: null, //此变量为截止时间戳与当前时间戳相减的差
				timeStamp: null, //此变量为倒计时截止的时间戳
				time2: null,
				phone: '',
				code: '',
				oldPassword: '',
				newPassword: ''
			}
		},
		created() {
			// window.addEventListener('beforeunload', () => {
			//   if (this.timeStamp) {
			//     localStorage.setItem("reduceTimeStamp2", JSON.stringify(this.timeStamp));
			//     clearInterval(this.timer);
			//   }
			// })
			// let temp = JSON.parse(localStorage.getItem("reduceTimeStamp2"));
			// let NowStamp = new Date().getTime();
			// //如果<=0说明倒计时已结束，将按钮恢复原始状态
			// if (+temp - NowStamp <= 0) {
			//   this.result = null;
			//   this.btnFlag = false;
			// } else {
			//   this.result = parseInt((temp - NowStamp) / 1000);
			//   this.formatSeconds(this.result)
			// }

		},

		mounted() {
			// if (this.result) this.getCode();
		},
		methods: {
			//点击下一步
			listEvt() {
				if (this.phone && test.mobile(this.phone)) {
					if (this.code) {
						this.$http.post('retrievePassword', {
							code: this.code,
							telephone: this.phone
						}).then(res => {

							if (res.code === 200) {
								this.index = 2
							} else {
								this.$message.error('验证码未通过')
							}
						})
					} else {
						this.$message.error('验证码不能为空')
					}
				} else {
					this.$message.error('手机号码错误')
				}
			},
			//确认修改密码
			sureUpdatePsd() {
				if (!test.passwordReg(this.oldPassword)) return this.$message.error('密码格式为英文、符号、数字单一或组合，且长度在6位及以上');
				if (this.newPassword != this.oldPassword) return this.$message.error('两次密码输入不一致');
				this.$http.post('updataPassword', {
					phoneNumber: this.phone,
					password: md5(this.newPassword)
				}).then(res => {
					if (res.code === 200) {
						this.$message.success('设置新密码成功！即将返回登录页面...')
						setTimeout(() => {
							this.$router.push({
								path: '/login'
							})
						}, 2000)
					}
				})

			},
			//倒计时时间
			countTime() {
				//如果result<=0,证明上一次读秒已结束，需要重新设置新的时间戳
				if (!this.result) {
					let currentStamp = new Date().getTime();
					this.timeStamp = new Date().getTime() + 300 * 200; //设置倒计时时间
					this.result = (this.timeStamp - currentStamp) / 1000;
					this.time2 = this.formatSeconds(this.result)
					this.result--;
				}
				this.timer = setInterval(() => {
					this.time2 = this.formatSeconds(this.result)
					this.result--;
					if (this.result === 0) {
						clearInterval(this.timer);
						this.timer = null;
						this.btnFlag = false;
					}
				}, 1000);
			},
			//获取验证码
			getCode() {
				if (this.phone && test.mobile(this.phone)) {
					if (!this.btnFlag) {
						if (this.phone) {
							this.btnFlag = !this.btnFlag;
							this.countTime();
							this.$http.post('getCodeApi', {
								phoneNumber: this.phone
							}).then(res => {
								if (res.code === 200) {
									this.$message.success('请查看你的手机并输入验证码')
								} else {
									this.$message.error(res.message)
								}
							})
						} else {
							this.$message.error('请先输入电话号码！')
						}
					}
				} else {
					this.$message.error('手机号码不正确')
				}



			},
			//时间转换时分秒
			formatSeconds(value) {
				let result = parseInt(value)
				let h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600);
				let m = Math.floor((result / 60 % 60)) < 10 ? '0' + Math.floor((result / 60 % 60)) : Math.floor((result /
					60 % 60));
				let s = Math.floor((result % 60)) < 10 ? '0' + Math.floor((result % 60)) : Math.floor((result % 60));

				let res = '';
				if (h !== '00') res += `${h}时`;
				if (m !== '00') res += `${m}分`;
				res += `${s}秒`;
				return res;
			}
		}
	}
</script>

<style lang="less" scoped>
	.retrieve {
		width: 1165px;
		height: 631px;
		background: #FFFFFF;
		box-shadow: 0px 4px 38px 0px rgba(53, 53, 53, 0.19);
		border-radius: 10px;
		padding: 21px 46px;
		margin: 20px auto;

		h3 {
			font-size: 36px;
			font-family: PingFang SC;
			font-weight: bold;
			color: #010101;
			text-align: center;
			margin-bottom: 70px;
		}

		.loginTop {
			display: flex;
			justify-content: space-between;
			font-size: 20px;
			font-family: PingFang SC;
			font-weight: bold;
			color: #999999;
			margin: 0 auto;
			margin-bottom: 40px;
			width: 560px;

			div {
				width: 280px;
				height: 82px;
				box-shadow: 0px 4px 38px 0px rgba(53, 53, 53, 0.19);
				cursor: pointer;
				font-size: 20px;
				font-family: PingFang SC;
				font-weight: bold;
				color: #999999;
				position: relative;
				text-align: center;
				line-height: 82px;

				span {
					font-size: 64px;
					font-family: PingFang SC;
					font-weight: bold;
					position: absolute;
					left: 40px;
				}
			}
		}

		.loginActive {
			color: #fff !important;
			background: #F02610;
		}

		.phone {
			width: 304px;
			margin: 0 auto;

			.phoneInput {
				position: relative;

				/deep/.el-input__inner {
					padding: 0 30px;
				}

				img {
					position: absolute;
					width: 12px;
					height: 15px;
					left: 10px;
					top: 12px;
				}
			}

			.forgetPsd {
				font-size: 14px;
				font-family: PingFang SC;
				font-weight: 400;
				color: #333333;
				text-align: right;
				margin-top: 30px;
			}

			.yzm {
				/deep/.el-input__inner {
					font-size: 14px;
					padding: 0 15px 0 30px !important;
				}

				margin-top: 34px;
				display: flex;
				position: relative;

				/deep/.el-input {
					width: 196px;
					height: 41px;
					border-radius: 5px 0px 0px 5px;

					/deep/.el-input__inner {
						padding: 0 30px;
					}
				}

				img {
					position: absolute;
					width: 12px;
					height: 15px;
					left: 10px;
					top: 12px;
				}

				div {
					width: 108px;
					height: 40px;
					border-radius: 0px 5px 5px 0px;
					font-size: 16px;
					font-family: PingFang SC;
					font-weight: 400;
					color: #010101;
					text-align: center;
					line-height: 38px;
					cursor: pointer;

					button {
						width: 100%;
						height: 100%;
						background: #FFFFFF;
					}
				}
			}

			.loginCls {
				margin-top: 62px;

				.el-button {
					width: 100%;
					background: #E51E0B;
					border-radius: 5px;
					color: #FFFFFF;
				}

				div {
					font-size: 14px;
					font-family: PingFang SC;
					font-weight: 400;
					color: #E51E0B;
					margin-top: 25px;
					cursor: pointer;
				}
			}
		}
	}
</style>
